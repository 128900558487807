<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6" cols="12">
            <b-form-group
                label="نام دوره"
                label-for="mc-first-name"
            >
              <b-form-input
                  v-model="academy.title"
                  id="mc-first-name"
                  placeholder="نام دوره"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" cols="12">
            <b-form-group
                label="توضیحات کوتاه دوره"
                label-for="mc-city3"
            >
              <b-form-input
                  v-model="academy.summary"
                  id="mc-city3"
                  placeholder="توضیحات کوتاه دوره"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="brands" cols="12" md="4">
            <b-form-group
                label="برند دوره"
                label-for="mc-city3"
            >
              <v-select
                  v-model="academy.brandId"
                  :clearable="false"
                  :options="brands"
                  :reduce="title => title.brandId"
                  label="title"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-row>
              <b-col md="6" cols="12" class="my-1">
                <b-form-group
                    label="دسته بندی"
                    label-for="mc-country"
                >
                  <v-select
                      v-model="academy.SelectedCategoryId"
                      :options="Categories"
                      label="name"
                      :reduce="name => name.academyCategoryId"
                      class="per-page-selector bg-white d-inline-block w-100"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div v-if="Properties.length>0" class="row ">
              <label class="px-2">جزئیات پیش فرض این دسته بندی (اختیاری) : </label>
              <div v-for="item in Properties" :key="item.academyCategoryPropertyId" class="col-md-12 my-2">
                <div class="row">
                  <div class="col-md-2"><span>{{ item.name }}</span></div>
                  <div class="col-md-3">
                    <input type="text" :ref="'Property'+item.academyCategoryPropertyId" class="form-control"
                           placeholder="مقدار را وارد کنید">
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
                label="تاریخ و زمان انتشار"
                label-for=""
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <input
                    :id="`start${1}`"
                    :state="errors.length > 0 ? false:null"
                    :value="createJalaliDate(academy.publishDate)"
                    class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    placeholder="تاریخ و زمان انتشار"
                    type="text"
                />
              </validation-provider>
              <custom-date-picker
                  v-model="academy.publishDate"
                  :element="`start${1}`"
                  :min="new Date(Date.now())"
                  auto-submit
                  class=""
                  color="#5c6bc0"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  input-format="YYYY-MM-DD HH:mm"
                  type="datetime"
              ></custom-date-picker>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    class="mb-2"
                    label="برچسب ها"
                    label-for="tagIds"
                >
                  <b-form-input
                      id="tagIds"
                      v-model="newTag.value"
                      placeholder="برچسب ها"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    class="mr-1 mt-2"
                    variant="success"
                    @click="addNewTag"
                >
                  افزودن برچسب جدید
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div class="d-flex align-items-center gap-2">
              <b-badge v-for="(item,idx) in academy.tags" :key="idx" class="mx-25 p-1 d-flex align-items-center" variant="primary">
                <feather-icon icon="XIcon" class="mx-25  text-danger cursor-pointer" @click="academy.tags.splice(idx,1)"></feather-icon>
                <span class="mt-25">
                {{item.value}}
                </span>
              </b-badge>
            </div>
          </b-col>
          <b-col cols="12 my-25">
            <label>جزئیات دوره : </label>
            <b-row>
              <b-col md="3" cols="12">
                <label>نام</label>
                <input v-model="academyProperty.academyPropertyName" type="text" class="form-control"
                       placeholder="مثال جنس">
              </b-col>
              <b-col md="3" cols="12">
                <label>مقدار</label>
                <input v-model="academyProperty.academyPropertyValue" type="text" class="form-control"
                       placeholder="مثال کتان">
              </b-col>
              <b-col class="my-2" md="2">
                <b-button @click="addNewProperty" variant="primary">افزودن</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="d-flex flex-wrap  align-items-center">
              <b-badge class="mx-25" v-for="(item,idx) in academyProperties " :key="idx" variant="primary">
                <feather-icon
                    @click="deleteProperty(idx)"
                    icon="TrashIcon"
                    class="  text-danger cursor-pointer"
                />
                <span class="mx-1">{{ item.name }}:{{ item.value }}</span>
              </b-badge>

            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="پیوست عکس"
                label-for="mc-country"
            >
              <b-form-file

                  accept=".jpg, .png, .gif"
                  placeholder="انتخاب عکس"
                  @input="makeBase64Pic"
                  ref="refInputEl"
                  drop-placeholder="Drop file here..."
                  multiple
              />
            </b-form-group>

          </b-col>
          <b-col v-if="academyPhotos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in academyPhotos" :key="idx" class="position-relative mx-2 bg-white rounded">
                <feather-icon @click="academyPhotos.splice(idx,1)" icon="TrashIcon" size="20"
                              class="text-danger position-absolute top-0 cursor-pointer"/>
                <img :src="item.blob" style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
              </div>

            </div>

          </b-col>
          <b-col cols="12">
            <addPriceAcademy @SendPrice="CallPrices"></addPriceAcademy>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                label="توضیحات"
                label-for="summary"
            >
                <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent"></ckEditorMain>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->

        </b-row>
      </b-form>

    </b-card>
    <b-card class="blog-edit-wrapper">
      <h2>سئو</h2>

      <b-form class="mt-2">
        <b-row>
          <b-col md="6" class="">
            <b-form-group
                label="عنوان"
                label-for="blog-edit-title"
                class=""
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="academy.seoTitle"
              />
            </b-form-group>
            <span class=""
                  :class="{'text-danger':academy.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{
                academy.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    label="آدرس"
                    label-for="blog-edit-seourl"
                    class=""
                >
                  <b-form-input
                      id="blog-edit-seourl"
                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                      ref="myInput"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    variant="warning"
                    class="mt-2 ml-2"
                    @click="copyText"
                >
                  کپی
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col cols="12" class="mt-2">
            <b-form-group
                label="توضیحات"
                label-for="blog-content"
                class="mb-2"
            >
              <b-form-textarea
                  v-model="academy.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span class=""
                  :class="{'text-danger':academy.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{
                academy.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>

          <b-card class="mt-1 w-100">

            <h2>نحوه نمایش در گوگل</h2>

            <div class="col-md-12 my-3">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ academy.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span style="overflow-wrap: anywhere">{{ academy.seourl.replaceAll(' ', '-') }}/</span>
                    <span>
                             {{ 'https://enzamode.ir/academy/' }}
                        <span class="serp-arrow"></span>
                      </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ academy.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                <div class="serp-title">
                  <h6>
                    {{ academy.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ academy.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    {{ 'https://enzamode.ir/academy/' }}
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ academy.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-card>

          <b-col>
            <!--            <pre v-if="dataPayload" v-html="dataPayload"></pre>-->
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="mr-1"
                @click="createAcademy"
            >
              ثبت نهایی
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>


<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BBadge,BFormCheckbox, BOverlay, BForm, BButton, BMedia, BCard, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from 'vue-select'
import {required} from '@validations'
import addPriceAcademy from '@/views/components/addPriceAcademy.vue'
import {GetPropertiesByCategoryId} from "@/libs/Api/academyCategoryProperties";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {GetAllAcademyCategory} from "@/libs/Api/academyCategory";
import {getUserData} from "@/auth/utils";
import {CreateAcademy} from "@/libs/Api/academy";
import Helper from "@/libs/Helper";
import {GetAllBrand} from "@/libs/Api/brands";
import ckEditorMain from "@/views/components/ckEditorMain";
export default {
  head() {
    return {
      title: 'ایجاد دوره'
    }
  },
  components: {
    ToastificationContent,
    BRow,
    BCol,
    BOverlay,
    addPriceAcademy,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    ckEditorMain,
    vSelect,
    BBadge,
    BMedia,
    BCard,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
  },
  data() {
    return {
      Categories: [],
      dataPayload: null,
      showOverlay: false,
      base64ImageSrc: '',
      brands:null,
      FinalPriceV: null,
      SinglePRiceV: null,
      SingleQty: null,
      PType: null,
      academyPhotos: [],
      newTag: {
        value: "",
        type: 2,
        objectId: 0,
        createDate: new Date(Date.now())
      },
      selectedDeliveryDay: 0,
      deliveryDays: [
        {
          name: '1 ماهه',
          id: 30
        },
        {
          name: '2 ماهه',
          id: 60
        },
        {
          name: '3 ماهه',
          id: 90
        },
        {
          name: '6 ماهه',
          id: 180
        },
      ],
      academyProperties: [],
      academyProperty: {
        academyPropertyName: '',
        academyPropertyValue: ''
      },
      Properties: [],
      variablePrices: false,
      academy: {
        seourl: '',
        brandId:0,
        publishDate:'',
        seoTitle: '',
        seoDescription: '',
        title: '',
        summary: '',
        academyDeliveryDays: 0,
        description: '',
        brand: '',
        tags:[],
        SelectedCategoryId: null,
      },


    }
  },
  async mounted() {
    await this.GetAllCategories();
    await this.getAllBrands();
  },
  watch: {
    'academy.SelectedCategoryId': function (val) {
      this.GetCategoryProperties(val);
    }
  },

  computed: {
    allCharactersToDash: {
      get: function () {
        return this.academy.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.academy.seourl = newValue
          // Remove all characters that are NOT number
          this.academy.seourl = this.academy.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.academy.seourl = this.academy.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.academy.seourl === "") {
          this.academy.seourl = null;
        }
      },
    },
  },
  methods: {
    addNewTag() {
      this.academy.tags.push(this.newTag)
      this.newTag = {
        value: "",
        type: 2,
        objectId: 0,
        createDate: new Date(Date.now())
      }
    },
    async getAllBrands() {
      let _this = this
      let getAllBrand = new GetAllBrand(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0,
      }
      getAllBrand.setParams(data)
      await getAllBrand.fetch(function (content) {
        _this.brands = content.data.requests

      }, function (error) {
        console.log(error)
      })
    },
    deleteProperty(idx) {
      this.academyProperties.splice(idx, 1)
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    addNewProperty() {
      //Get Properties
      let tmpProperty = {
        name: '',
        value: ''
      }
      tmpProperty.name = this.academyProperty.academyPropertyName
      tmpProperty.value = this.academyProperty.academyPropertyValue
      const clone = {...tmpProperty}
      this.academyProperties.push(clone)
      tmpProperty.name = ''
      tmpProperty.value = ''
      this.academyProperty =
          {
            academyPropertyName: '',
            academyPropertyValue: ''
          }
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس دوره کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    CallPrices(FinalPriceV, SinglePriceV, SingleQty, PType) {
      this.FinalPriceV = FinalPriceV
      this.SinglePRiceV = SinglePriceV
      this.SingleQty = SingleQty
      this.PType = PType
    },

    setEditorContent(content){
      this.academy.description = content
    },
    async createAcademy() {
      if (this.academy.title === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا نام دوره را وارد کنید`,
          },
        })
      } else {
        if (!this.PType) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              icon: 'CloseIcon',
              variant: 'danger',
              text: `لطفا نوع دوره را انتخاب کنید`,
            },
          })
        } else {
          if (this.PType === 1 && !this.SinglePRiceV && !this.SingleQty) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا قیمت مورد نظر را ثبت کنید`,
              },
            })

          } else if (this.PType === 2 && !this.FinalPriceV) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا قیمت های مورد نظر را ثبت کنید`,
              },
            })
          } else if(this.academy.seourl==='' ||this.academy.seoTitle==='' ||this.academy.seoDescription===''){
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات ناموفق`,
                icon: 'CloseIcon',
                variant: 'danger',
                text: `لطفا داده های اولیه سئو را ثبت کنید`,
              },
            })
          }else{
            //Set Image
            let tmpPhotos = []
            let tmpPhoto = {
              base64: "",
              priority: 0
            }

            if (this.academyPhotos.length > 0) {
              this.academyPhotos.forEach((item) => {
                tmpPhoto.base64 = item.base64
                tmpPhoto.priority = item.priority
                const clone = {...tmpPhoto}
                tmpPhotos.push(clone)
                tmpPhoto.base64 = ''
                tmpPhoto.priority = 0
              })
              const idx = tmpPhotos.findIndex(e => e.priority === 1)
              if (idx >= tmpPhotos.length) {
                let k = 0 - tmpPhotos.length + 1;
                while (k--) {
                  tmpPhotos.push(undefined);
                }
              }
              tmpPhotos.splice(0, 0, tmpPhotos.splice(idx, 1)[0]);
            }

            // Set academy Type
            if (this.PType === 2) {
              this.variablePrices = true
            }
            // Set Prices
            let tmpPrices = []
            let SinglePrice = {
              vendorUserId: getUserData().userId,
              priceValue: parseInt(this.SinglePRiceV),
              quantity: parseInt(this.SingleQty),
            }
            if (this.PType === 1) {
              tmpPrices.push(SinglePrice)
            } else if (this.PType === 2) {
              tmpPrices = this.FinalPriceV
            }
            //Get Properties
            let tmpProperty = {
              name: '',
              value: ''
            }
            this.Properties.forEach((item) => {
              if (this.$refs['Property' + item.academyCategoryPropertyId][0].value === '') {
              } else {
                tmpProperty.name = item.name
                tmpProperty.value = this.$refs['Property' + item.academyCategoryPropertyId][0].value
                const clone = {...tmpProperty}
                this.academyProperties.push(clone)
                tmpProperty.name = ''
                this.$refs['Property' + item.academyCategoryPropertyId][0].value = ''
                tmpProperty.value = ''
              }
            })
              this.$refs.editorContent.emitDataToParent()
            let _this = this;
            _this.showOverlay = true
            let createAcademy = new CreateAcademy(_this);
            let data = {
              title: this.academy.title,
              summary: this.academy.summary,
              description: this.academy.description,
              academyCategoryId: this.academy.SelectedCategoryId,
              seourl: this.academy.seourl,
              seoTitle: this.academy.seoTitle,
              seoDescription: this.academy.seoDescription,
              photos: tmpPhotos,
              expireDate: '',
              isTolerated: false,
              academyDeliveryDays: 0,
              publishDate:this.academy.publishDate,
              variablePrices: this.variablePrices,
              country: '',
              brandId:this.academy.brandId,
              createDate: new Date(Date.now()),
              properties: this.academyProperties,
              prices: tmpPrices,
              tags:this.academy.tags,
              isInStock: true,
            }
            _this.dataPayload = data
            createAcademy.setData(data);

            await createAcademy.fetch(function (content) {
              _this.$toast({
                component: ToastificationContent,
                position: 'bottom-center',
                props: {
                  title: `عملیات موفق`,
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `دوره ساخته شد.`,
                },
              })

              _this.$router.push('/pages/academy/AcademyList')
              _this.showOverlay = false
            }, function (error) {
              console.log(error)
              _this.showOverlay = false

            })
          }
        }
      }

    },
    async GetCategoryProperties(val) {
      let _this = this
      _this.showOverlay = true
      let getPropertiesByCategoryId = new GetPropertiesByCategoryId(_this)
      let data = {
        categoryId: val
      }
      getPropertiesByCategoryId.setParams(data)
      await getPropertiesByCategoryId.fetch(function (content) {
        _this.showOverlay = false
        content.forEach((item) => {
          _this.Properties.push(item)

        })

      }, function (error) {
        console.log(error)
        _this.showOverlay = false
      })

    }
    ,
    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        priority: 1
      }
      if (this.$refs.refInputEl.files.length > 4 || this.academyPhotos.length > 4) {
        this.$toast.error('میتوانید تا 5 عکس را همزمان اضافه کنید')
      } else {
        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.academyPhotos.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })

      }
      this.$refs.refInputEl.reset()


    }
    ,
    async GetAllCategories() {
      let _this = this
      let getAllAcademyCategory = new GetAllAcademyCategory(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0
      }
      getAllAcademyCategory.setParams(data)
      await getAllAcademyCategory.fetch(function (content) {
        _this.Categories = content.academyCategories

      }, function (error) {
        console.log(error)
      })
    }
    ,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.ql-container {
  height: 250px!important;
}
</style>
